import * as Yup from 'yup';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Link, Stack, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField, RHFCheckbox } from '../../../components/hook-form';
import Swal from 'sweetalert2';

// ----------------------------------------------------------------------

const BASE_URL = process.env.REACT_APP_BASE_URL

async function loginUser({
  username,
  password
}) {
  return fetch(BASE_URL + 'admin/login', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      username,
      password
    })
  })
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      return data.accessToken;
    });
}


export default function LoginForm() {
  const navigate = useNavigate();

  const [username, setUserName] = useState();
  const [password, setPassword] = useState();


  const handleSubmit = async e => {
    e.preventDefault();
    const token = await loginUser({
      username,
      password
    });

    if (token != null) {
      localStorage.setItem("token", token)
      localStorage.setItem("username", username)
      console.log(username)
      navigate('/dashboard/users', { replace: true });
    }
    else {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Email or password is incorrect!',
      })
    }

  }

  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  const defaultValues = {
    email: '',
    password: '',
    remember: true,
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const onChangeUsername = (event) => {
    setUserName(event.target.value)
   }

   const onChangePassword = (event) => {
    setPassword(event.target.value)
   }

  const {
    // handleSubmit,
    formState: { isSubmitting },
  } = methods;

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit}>
      <Stack spacing={3}>
        <RHFTextField name="email" label="Email address" 
        onChange={onChangeUsername}
        value={username}
        />

        <RHFTextField
          name="password"
          label="Password"
          onChange={onChangePassword}
          value={password}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        {/* <RHFCheckbox name="remember" label="Remember me" /> */}
        {/* <Link variant="subtitle2" underline="hover">
          Forgot password?
        </Link> */}
      </Stack>

      <LoadingButton color="success" fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
        Sign in
      </LoadingButton>
    </FormProvider>
  );
}
